.mainContainer{
    display:flex;
    justify-content:center;
    width:100%;
    margin-top: 2rem;
}
.seclectionproductSection{
    width:60%;
    margin-top: 2rem;
    position: absolute;
    top: 0%;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
}
.productSection{
    width:100%;
}
.productDiv{
    margin: 0.1rem 0;
    padding: 10PX 0PX;
    border-bottom: 1px solid;
}
.productTitle{
  
    font-size: 18px;
    /* margin-left: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100px
}
.btnDownload{
    background-color: #000;
    color: #fff;
    margin: 1rem; 
}
.btnDownload2{
    background-color: #000;
    color: #fff;
    padding: 0.6rem 2rem;
    text-decoration: none;
}
.btnDownload2:hover{
    background-color: #fff;
    color: #000;
   border: 1px solid #000;
border-radius:5px;
}
.btnDownload:hover{
    background-color: #000;
    color: #fff;
}

/* .sectiontitlediv{
    display: flex;
    margin: 2rem;
    justify-content: center;
} */

.searchDiv{
    display: flex;
    padding: 2rem;
    justify-content: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    margin: 0.1rem;
}

.selectedProducts{
    border: 1px solid #918686;
    border-radius: 10px;
    padding: 1rem;
}
.downloadDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cursor{
    cursor: pointer;
}
.productList{
    padding: 1rem;
    overflow: scroll;
    height: 100vh;
    border-radius: 20px;
    margin: 1rem;
}

.loaderDiv{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.loaderoverlay{
    background: #000;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 1%;
    left: 1%;
    opacity: 0.2;
}
.overlaydiv{
    width: 100%;
    background: #000;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0%;
    left: 0%;
    opacity: 0.5;
}
.viewProductsContent{
    overflow: scroll;
    height: 400px;
}
.viewlive{
    position: fixed;
    left: 0;
    bottom: 0%;
    background: #a8a5b6;
    width: 18%;
    height: 100vh;
    transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}
.viewliveContent{
    height: 100vh;
    overflow: scroll;
    padding-bottom:10rem;
}

.viewliveContent {
    overflow-y: scroll;
    scrollbar-width: none; 
  }
  
  .viewliveContent::-webkit-scrollbar {
    display: none;
  }
  .cartRow{
    display: flex;
    height: fit-content;
    overflow-y: scroll;
  }
  .cartCol1{
    width: 15%;
  }
  .cartCol2{
    width: 75%;
    height: 100vh;
    mix-blend-mode: multiply;
  }
  @media screen and (max-width: 500px){
    .cartRow {
        font-size: 3vw;
    }
  }