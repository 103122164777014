.mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.seclectionproductSection {
    width: 60%;
    margin-top: 2rem;
    position: absolute;
    top: 0%;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
}

.productSection {
    width: 100%;
}

.productDiv {
    margin: 0.1rem 0;
    padding: 10PX 0PX;
    border-bottom: 1px solid;
}

.productTitle {

    font-size: 18px;
    /* margin-left: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100px
}

.btnDownload {
    background-color: #000;
    color: #fff;
    margin: 1rem;
}

.btnDownload2 {
    background-color: #000;
    color: #fff;
    padding: 0.6rem 2rem;
    text-decoration: none;
}

.btnDownload2:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
}

.btnDownload:hover {
    background-color: #000;
    color: #fff;
}

/* .sectiontitlediv{
    display: flex;
    margin: 2rem;
    justify-content: center;
} */

.searchDiv {
    display: flex;
    padding: 2rem;
    justify-content: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    margin: 0.1rem;
}

.selectedProducts {
    border: 1px solid #918686;
    border-radius: 10px;
    padding: 1rem;
}

.downloadDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cursor {
    cursor: pointer;
}

.productList {
    padding: 1rem;
    overflow: scroll;
    height: 100vh;
    border-radius: 20px;
    margin: 1rem;
}

.loaderDiv {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loaderoverlay {
    background: #000;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 1%;
    left: 1%;
    opacity: 0.2;
}

.overlaydiv {
    width: 100%;
    background: #000;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0%;
    left: 0%;
    opacity: 0.5;
}

.viewProductsContent {
    overflow: scroll;
    height: 400px;
}

.viewlive {
    position: fixed;
    left: 0;
    bottom: 0%;
    background: #a8a5b6;
    width: 18%;
    height: 100vh;
    transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}

.viewliveContent {
    height: 100vh;
    overflow: scroll;
    padding-bottom: 10rem;
}

.viewliveContent {
    overflow-y: scroll;
    scrollbar-width: none;
}

.viewliveContent::-webkit-scrollbar {
    display: none;
}

.cartRow {
    display: flex;
    height: fit-content;
    overflow-y: scroll;
}

.cartCol1 {
    width: 15%;
}

.cartCol2 {
    width: 75%;
    height: 100vh;
    mix-blend-mode: multiply;
}

@media screen and (max-width: 500px) {
    .cartRow {
        font-size: 3vw;
    }
}

.neo_btn_container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    justify-content: center;
}

.product_neo_btn {
    margin: 5px;
    padding: 7px 10px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    background: none;
}

.product_left_arrow {
    font-size: 52px;
    color: #111F6A;
}

.product_right_arrow {
    font-size: 52px;
    color: #63CFC2;
}

.active-page-button {
    background-color: #007bff;
    color: #fff;
}

.css-t3ipsp-control {
    width: 100% !important;
}

.css-1fdsijx-ValueContainer {
    width: 100% !important;

}

@media screen and (max-width: 767px) {
    .scanInput{
        flex: 0 0 65%;
    }
    .downloadDiv{
        flex: 0 0 95%;   
    }
    .DownloadAndView{
        flex: 0 0 95%;  
        justify-content: space-between !important;     
    }
    .liveAddedBtn{
        flex: 0 0 20%;    
    }
    .neo_btn_container {
   overflow-x:scroll;
    }
    .GlobalSearch{
        width:100% !important;
    }
    .productList{
        margin: 0rem !important;
    }
    .viewlive{
        bottom: -35% !important;
        width: 100% !important;
    }
    .seclectionproductSection {
        width: 95% !important;
        margin-top: 1rem;
        top: 5%;
    }
    .productList table.table.table-bordered {
            font-size: 11px;
    }
    .productList table.table.table-bordered label{
            font-size: 11px;
    }
    .productList table.table.table-bordered label> button{
            font-size: 11px;
    }
    /* .productList table.table.table-bordered tbody tr td{
        height:50px;  
    } */
    .productTitle{
        height: unset !important;
    }
    
    .productTitle img{
        height: 50px;
    width:50px;
    }
    .viewliveContent {
        padding-bottom: 21rem !important;
    }
}
    